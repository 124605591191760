/*
   Featured Work
*/
section.featured_work{}
.clear{clear:both;}
.featured_videos .grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 0em 0 0em;
    max-width: 1200px;
    list-style: none;
    text-align: center;
}

/* Common style */
.featured_videos .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 600px;
    min-height: 200px;
    max-height: 348px;
    width: 48%;
    height: auto;
    background: transparent;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
    margin:auto;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 94.5%;
}

/* Media queries */
@media screen and (max-width: 50em) {
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .featured_videos .grid figure {
        position: relative;
        float: none;
        overflow: hidden;
        margin: 10px 1%;
        width: 100%;
        height: auto;
        background: transparent;
        text-align: center;
        cursor: pointer;
    }
}