/*
    Menu
*/
.hide{display:none;}
.tilted_menu{
    width: 0; height: 0;
    border-top: 0px solid transparent;
    border-bottom: 2000px solid transparent;
    border-right:0px solid #232323;
    /*width:500px;*/height:100vh;position:fixed;top:0px;right:0px;z-index:100;
    -webkit-transition:0.5s ease-in-out;-ms-transition:0.5s ease-in-out;-moz-transition:0.5s ease-in-out;-o-transition:0.5s ease-in-out;transition:0.5s ease-in-out;

}
.tilted_menu .menu_content{margin:auto;padding-top:24vh;position:relative;right:-120px;}
.tilted_menu .menu_content ul{width:360px;text-align:right;list-style:none;}
.tilted_menu .menu_content ul li{/*font-family:Gotham_XLight;*/font-size:33px;margin-bottom:20px;text-align:right;}
.tilted_menu .menu_content ul li a{
    font-weight:300;letter-spacing:0.17px;
    color:#fff;text-align:right;text-decoration:none;font-family: 'Raleway', sans-serif;text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.tilted_menu .menu_content ul li a:hover,
.tilted_menu .menu_content ul li a.active{color:#cc9a60;}
.tilted_menu.active{border-right:500px solid #232323;}