body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*, *:before, *:after {
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-text-size-adjust: none;
}
body{font-family: 'Raleway', sans-serif;}
.logo{
  z-index: 10000;
  letter-spacing: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 34px;
  padding-left: 25px;
  text-transform: uppercase;
  position: fixed;
  top: 10px;left:20px;
  width: 80px;
  height: 55px;
  background: transparent url(https://austincameron.com/res/images/logo_gray.png) no-repeat center center;
  background-size: 70px auto;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.video-react{position:relative;z-index:-1;  }
.hero_copy{
  overflow:hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  position:absolute;z-index:20;
  width:100%;height:100%;
  background: -webkit-linear-gradient(
          bottom,
          #222222,
          rgba(255, 255, 255, 0)
  );
  background: linear-gradient(
          to top,
          #222222,
          rgba(255, 255, 255, 0)
  );
}
.hero_copy svg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 79px;
}

@media (max-width: 699px) {
  .svg--lg {
    display: none;
  }
}

@media (min-width: 700px) {
  .svg--sm {
    display: none;
  }
}
.hero_copy h2{
  width:100%;
  font-size:68px;
  position:absolute;bottom:65px;
  color:#fff;text-align:center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', sans-serif;font-weight:300;
}
.hero_copy h3{
  width:100%;letter-spacing:0.9px;
  font-size:32px;font-style:italic;
  position:absolute;bottom:55px;
  color:#e9f5ff;text-align:center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', sans-serif;font-weight:200;
}


.about_text_header{}
.about_text_header h4{letter-spacing:3px;color:#cc9a60;text-align:center;font-size:20px;font-family: 'Montserrat-Bold', sans-serif;text-transform:uppercase;margin:40px auto 30px;}
.about_text_header h2{letter-spacing:2px;width:73%;color:#010101;text-align:center;line-height:32px;font-size:24px;font-family: 'Montserrat', sans-serif;margin:0px auto 10px;}
.about_text_header h3{width:68%;color:#4f4f4f;text-align:center;line-height:26px;font-size:17px;font-family: 'Raleway', sans-serif;font-weight:400;margin:0px auto 0px;}
.about_text_header .cta.wide{width:210px;}
.about_text_header .cta{
    box-shadow: 0 2px 10px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.23);
    border-radius:8px;padding:0px;margin:20px auto 30px;background-color:#cc9a60;color:#fff;text-decoration:none;display:block;text-align:center;width:148px;height:44px;text-transform:uppercase;line-height:45px;font-size:15px;font-family: 'Raleway', sans-serif;font-weight:600;}

.homepage_video{width:100%;min-height:236px;padding-bottom: 60px;height:auto;background-color:#050505;}
.homepage_video h2{letter-spacing:2px;text-transform:uppercase;text-align:center;width:90%;padding-top:45px;margin:0px auto 40px;color:#fff;font-family: 'Montserrat', sans-serif;font-size:26px;line-height:36px;}
.homepage_video iframe,
.ReactYoutube,
.homepage_video embed{margin:0px auto 0px;display:block;max-width:711px;}
.about_text_header .cta:hover,
.homepage_video iframe:hover{
  -webkit-transform:scale(1.04);
          transform:scale(1.04);
}
.ReactYoutube,
iframe,embed{width:100% !important;height:400px;}
.ReactYoutube-PlayAwait{width:100% !important;height:100% !important;}

.text_header{
  width:100%;height:auto;
}
.text_header h5{
  font-family: 'Playball', cursive;letter-spacing:2px;font-weight:400;
  margin-top: 40px;
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: #4d4d4d;
  margin-bottom: 40px;
}
.text_header h5:after {
  content: '';
  display: block;
  margin: auto;
  width: 10%;
  height: 10px;
  border-bottom: 1px solid #cc9a60;
}




/*
   Fade In & Up
*/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

/*
   Page Transitions
*/
.animate-page-in {
  -webkit-animation: fadeInPage .4s ease-in;
  animation: fadeInPage .4s ease-in;
  background-color:#222;
}
.animate-page-out {
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
  opacity: 0;
  background-color:#222;
}
@-webkit-keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}






















  @media (max-width: 768px) { /* Responsive */
      header{
         /* background: -webkit-linear-gradient( top, #666, rgba(255, 255, 255, 0.0) );
          background: linear-gradient( to bottom, #666, rgba(255, 255, 255, 0.0) );*/
          height:50px !important;
      }
      .logo {
          top: -5px;
          left: -10px;
          background-size: 40px auto;
      }
      body #nav-toggle-label {
          right: 2% !important;
          top: -5px !important;
      }
      body #nav-toggle:checked+#nav-toggle-label,
      body #nav-toggle:checked+#nav-toggle-label.slide-out {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
      }

      .about_text_header h2 {
          letter-spacing: 1.25px;
          width: 90%;
          line-height: 24px;
          font-size: 15px;
      }
      .about_text_header h3 {
          width: 94%;
          line-height: 24px;
          font-size: 11px;
          margin: 0px auto 30px;
      }
      .ReactYoutube,
      iframe,embed{width:90% !important;height:250px;}
      .quote-box{width:90% !important;margin:0px auto 15px !important;display:block !important;}
      .grid{max-width:90%;}
      .grid figure {min-width:300px;}
      .text_header h5{font-size:32px;}
      .contact_form.fadeInUp .sub_container {
          width: 100% !important;
      }
      .quotation-mark {
          margin-bottom: 22px;
          font-size: 84px;
      }
      .quote-text {
          font-size: 16px;
      }
      .quote-box p.blog-post-bottom{margin-top:10px;margin-bottom:-5px;}
      .homepage_video h2 {
          letter-spacing: 1.5px;
          width: 100%;
          padding-top: 30px;
          margin: 0px auto 25px;
          font-size: 11px;
          line-height: 22px;
      }
      footer .bottom{text-align: center;padding-top: 9px !important;}
      footer .bottom .footer_nav,
      footer .copyright{float:none !important;margin:auto;display:block;text-align: center;}
      footer .bottom .footer_nav ul li a {font-size: 13px;}
      footer .bottom .footer_nav ul { float: none;}

  }

@media (max-width: 500px) { /* Responsive */

    .hero_copy svg{height:30px;}
    .hero_copy h2{bottom:36px;font-size:32px;}
    .hero_copy h3{bottom:26px;font-size:16px;}
}







.video-react .video-react-big-play-button{
    left: -1000px;
    display:none !important;
}
/*
   Featured Work
*/
section.featured_work{}
.clear{clear:both;}
.grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 0em 0 0em;
    max-width: 1000px;
    list-style: none;
    text-align: center;
}

/* Common style */
.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 480px;
    min-height: 200px;
    max-height: 312px;
    width: 48%;
    height: auto;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
    margin:auto;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 94.5%;
}

/* Individual effects */

/*---------------*/
/***** Julia *****/
/*---------------*/

figure.effect-julia {
    background: #2f3238;
}

figure.effect-julia img {
    max-width: none;
    height: 400px;
    -webkit-transition: opacity 1s, -webkit-transform 1s;
    transition: opacity 1s, -webkit-transform 1s;
    transition: opacity 1s, transform 1s;
    transition: opacity 1s, transform 1s, -webkit-transform 1s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-julia figcaption {
    text-align: left;
}

figure.effect-julia h2 {
    position: relative;
    padding: 0.5em 0;
}

figure.effect-julia p {
    display: inline-block;
    margin: 0 0 0.25em;
    padding: 0.4em 1em;
    background: rgba(255,255,255,0.9);
    color: #2f3238;
    text-transform: none;
    font-weight: 500;
    font-size: 75%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-360px,0,0);
    transform: translate3d(-360px,0,0);
}

figure.effect-julia p:first-child {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

figure.effect-julia:hover img {
    opacity: 0.4;
    -webkit-transform: scale3d(1.1,1.1,1);
    transform: scale3d(1.1,1.1,1);
}

figure.effect-julia:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/*-----------------*/
/***** Goliath *****/
/*-----------------*/

figure.effect-goliath {
    background: #df4e4e;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-goliath img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
}

figure.effect-goliath p {
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0,50px,0);
    transform: translate3d(0,50px,0);
}

figure.effect-goliath:hover img {
    -webkit-transform: translate3d(0,-80px,0);
    transform: translate3d(0,-80px,0);
}

figure.effect-goliath:hover h2 {
    -webkit-transform: translate3d(0,-100px,0);
    transform: translate3d(0,-100px,0);
}

figure.effect-goliath:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/*-----------------*/
/***** Hera *****/
/*-----------------*/

figure.effect-hera {
    background: #303fa9;
}

figure.effect-hera h2 {
    font-size: 158.75%;
}

figure.effect-hera h2,
figure.effect-hera p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.effect-hera figcaption::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    border: 2px solid #fff;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.effect-hera p {
    width: 100px;
    text-transform: none;
    font-size: 121%;
    line-height: 2;
}

figure.effect-hera p a {
    color: #fff;
}

figure.effect-hera p a:hover,
figure.effect-hera p a:focus {
    opacity: 0.6;
}

figure.effect-hera p a i {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-hera p a:first-child i {
    -webkit-transform: translate3d(-60px,-60px,0);
    transform: translate3d(-60px,-60px,0);
}

figure.effect-hera p a:nth-child(2) i {
    -webkit-transform: translate3d(60px,-60px,0);
    transform: translate3d(60px,-60px,0);
}

figure.effect-hera p a:nth-child(3) i {
    -webkit-transform: translate3d(-60px,60px,0);
    transform: translate3d(-60px,60px,0);
}

figure.effect-hera p a:nth-child(4) i {
    -webkit-transform: translate3d(60px,60px,0);
    transform: translate3d(60px,60px,0);
}

figure.effect-hera:hover figcaption::before {
    opacity: 1;
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
}

figure.effect-hera:hover h2 {
    opacity: 0;
    -webkit-transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
    transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
}

figure.effect-hera:hover p i:empty {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0); /* just because it's stronger than nth-child */
    opacity: 1;
}


/*-----------------*/
/***** Selena *****/
/*-----------------*/

figure.effect-selena {
    background: #fff;
}

figure.effect-selena img {
    opacity: 0.95;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

figure.effect-selena:hover img {
    -webkit-transform: scale3d(0.95,0.95,1);
    transform: scale3d(0.95,0.95,1);
}

figure.effect-selena h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
}

figure.effect-selena p {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg);
    transform: perspective(1000px) rotate3d(1,0,0,90deg);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}

figure.effect-selena:hover h2 {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-selena:hover p {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
    transform: perspective(1000px) rotate3d(1,0,0,0);
}

/*-----------------*/
/***** Terry *****/
/*-----------------*/

figure.effect-terry {
    background: #34495e;
}

figure.effect-terry figcaption {
    padding: 1em;
}

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
    position: absolute;
    width: 200%;
    height: 200%;
    border-style: solid;
    border-color: #101010;
    content: '';
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-terry figcaption::before {
    right: 0;
    bottom: 0;
    border-width: 0 70px 60px 0;
    -webkit-transform: translate3d(70px,60px,0);
    transform: translate3d(70px,60px,0);
}

figure.effect-terry figcaption::after {
    top: 0;
    left: 0;
    border-width: 15px 0 0 15px;
    -webkit-transform: translate3d(-15px,-15px,0);
    transform: translate3d(-15px,-15px,0);
}

figure.effect-terry img,
figure.effect-terry p a {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-terry img {
    opacity: 0.85;
}

figure.effect-terry h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.4em 10px;
    width: 50%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
}

@media screen and (max-width: 920px) {
    figure.effect-terry h2 {
        padding: 0.75em 10px;
        font-size: 120%;
    }
}

figure.effect-terry p {
    float: right;
    clear: both;
    text-align: left;
    text-transform: none;
    font-size: 111%;
}

figure.effect-terry p a {
    display: block;
    margin-bottom: 1em;
    color: #fff;
    opacity: 0;
    -webkit-transform: translate3d(90px,0,0);
    transform: translate3d(90px,0,0);
}

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
    color: #f3cf3f;
}

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-terry:hover img {
    opacity: 0.6;

}

figure.effect-terry:hover h2,
figure.effect-terry:hover p a {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-terry:hover p a {
    opacity: 1;
}

figure.effect-terry:hover p a:first-child {
    -webkit-transition-delay: 0.025s;
    transition-delay: 0.025s;
}

figure.effect-terry:hover p a:nth-child(2) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-terry:hover p a:nth-child(3) {
    -webkit-transition-delay: 0.075s;
    transition-delay: 0.075s;
}

figure.effect-terry:hover p a:nth-child(4) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}


/*-----------------*/
/***** Apollo *****/
/*-----------------*/

figure.effect-apollo {
    background: #3498db;
}
figure.effect-apollo.livewarm {
    background: #E97E33;
}
figure.effect-apollo.cuddleclones {
    background: #b866b9;
}
figure.effect-apollo.millno8 {
    background: #986859;
}

figure.effect-apollo img {
    opacity: 0.95;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale3d(1.05,1.05,1);
    transform: scale3d(1.05,1.05,1);
}

figure.effect-apollo figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
}

figure.effect-apollo p {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 3em;
    padding: 0 1em;
    max-width: 450px;
    border-right: 4px solid #fff;
    text-align: right;
    opacity: 0;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
    z-index:5;
    font-family: 'Montserrat', sans-serif;font-weight:600;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.7);

}

figure.effect-apollo h2 {
    text-align: left;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.7);
}

figure.effect-apollo:hover img {
    opacity: 0.6;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

figure.effect-apollo:hover figcaption::before {
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}

figure.effect-apollo:hover p {
    opacity: 1;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

/*-----------------*/
/***** Kira *****/
/*-----------------*/

figure.effect-kira {
    background: #fff;
    text-align: left;
}

figure.effect-kira img {
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-kira figcaption {
    z-index: 1;
}

figure.effect-kira p {
    padding: 2.25em 0.5em;
    font-weight: 600;
    font-size: 100%;
    line-height: 1.5;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0,-10px,0);
    transform: translate3d(0,-10px,0);
}

figure.effect-kira p a {
    margin: 0 0.5em;
    color: #101010;
}

figure.effect-kira p a:hover,
figure.effect-kira p a:focus {
    opacity: 0.6;
}

figure.effect-kira figcaption::before {
    position: absolute;
    top: 0;
    right: 2em;
    left: 2em;
    z-index: -1;
    height: 3.5em;
    background: #fff;
    content: '';
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0,4em,0) scale3d(1,0.023,1) ;
    transform: translate3d(0,4em,0) scale3d(1,0.023,1);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
}

figure.effect-kira:hover img {
    opacity: 0.5;
}

figure.effect-kira:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-kira:hover figcaption::before {
    opacity: 0.7;
    -webkit-transform: translate3d(0,5em,0) scale3d(1,1,1) ;
    transform: translate3d(0,5em,0) scale3d(1,1,1);
}

/*-----------------*/
/***** Steve *****/
/*-----------------*/

figure.effect-steve {
    z-index: auto;
    overflow: visible;
    background: #000;
}

figure.effect-steve:before,
figure.effect-steve h2:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000;
    content: '';
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-steve:before {
    box-shadow: 0 3px 30px rgba(0,0,0,0.8);
    opacity: 0;
}

figure.effect-steve figcaption {
    z-index: 1;
}

figure.effect-steve img {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: perspective(1000px) translate3d(0,0,0);
    transform: perspective(1000px) translate3d(0,0,0);
}

figure.effect-steve h2,
figure.effect-steve p {
    background: #fff;
    color: #2d434e;
}

figure.effect-steve h2 {
    position: relative;
    margin-top: 2em;
    padding: 0.25em;
}

figure.effect-steve h2:before {
    box-shadow: 0 1px 10px rgba(0,0,0,0.5);
}

figure.effect-steve p {
    margin-top: 1em;
    padding: 0.5em;
    font-weight: 800;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale3d(0.9,0.9,1);
    transform: scale3d(0.9,0.9,1);
}

figure.effect-steve:hover:before {
    opacity: 1;
}

figure.effect-steve:hover img {
    -webkit-transform: perspective(1000px) translate3d(0,0,21px);
    transform: perspective(1000px) translate3d(0,0,21px);
}

figure.effect-steve:hover h2:before {
    opacity: 0;
}

figure.effect-steve:hover p {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

/*-----------------*/
/***** Moses *****/
/*-----------------*/

figure.effect-moses {
    background: -webkit-linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
    background: -webkit-linear-gradient(135deg, #EC65B7 0%,#05E0D8 100%);
    background: linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
}

figure.effect-moses img {
    opacity: 0.85;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-moses h2,
figure.effect-moses p {
    padding: 20px;
    width: 50%;
    height: 50%;
    border: 2px solid #fff;
}

figure.effect-moses h2 {
    padding: 20px;
    width: 50%;
    height: 50%;
    text-align: left;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(10px,10px,0);
    transform: translate3d(10px,10px,0);
}

figure.effect-moses p {
    float: right;
    padding: 20px;
    text-align: right;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}

figure.effect-moses:hover h2 {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-moses:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-moses:hover img {
    opacity: 0.6;
}

/*---------------*/
/***** Jazz *****/
/*---------------*/

figure.effect-jazz {
    background: -webkit-linear-gradient(-45deg, #f3cf3f 0%,#f33f58 100%);
    background: -webkit-linear-gradient(135deg, #f3cf3f 0%,#f33f58 100%);
    background: linear-gradient(-45deg, #f3cf3f 0%,#f33f58 100%);
}

figure.effect-jazz img {
    opacity: 0.9;
}

figure.effect-jazz figcaption::after,
figure.effect-jazz img,
figure.effect-jazz p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-jazz figcaption::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    content: '';
    opacity: 0;
    -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

figure.effect-jazz h2,
figure.effect-jazz p {
    opacity: 1;
    -webkit-transform: scale3d(0.8,0.8,1);
    transform: scale3d(0.8,0.8,1);
}

figure.effect-jazz h2 {
    padding-top: 26%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-jazz p {
    padding: 0.5em 2em;
    text-transform: none;
    font-size: 0.85em;
    opacity: 0;
}

figure.effect-jazz:hover img {
    opacity: 0.7;
    -webkit-transform: scale3d(1.05,1.05,1);
    transform: scale3d(1.05,1.05,1);
}

figure.effect-jazz:hover figcaption::after {
    opacity: 1;
    -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
    transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
}

figure.effect-jazz:hover h2,
figure.effect-jazz:hover p {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
    background: #030c17;
}

figure.effect-ming img {
    opacity: 0.9;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale3d(1.4,1.4,1);
    transform: scale3d(1.4,1.4,1);
}

figure.effect-ming h2 {
    margin: 20% 0 10px 0;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-ming p {
    padding: 1em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

figure.effect-ming:hover h2 {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

figure.effect-ming:hover figcaption {
    background-color: rgba(58,52,42,0);
}

figure.effect-ming:hover img {
    opacity: 0.4;
}

/*---------------*/
/***** Lexi *****/
/*---------------*/

figure.effect-lexi {
    background: -webkit-linear-gradient(-45deg, #000 0%,#fff 100%);
    background: -webkit-linear-gradient(135deg, #000 0%,#fff 100%);
    background: linear-gradient(-45deg, #000 0%,#fff 100%);
}

figure.effect-lexi img {
    margin: -10px 0 0 -10px;
    max-width: none;
    width: calc(100% + 10px);
    opacity: 0.9;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(10px,10px,0);
    transform: translate3d(10px,10px,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-lexi figcaption::before,
figure.effect-lexi p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-lexi figcaption::before {
    position: absolute;
    right: -100px;
    bottom: -100px;
    width: 300px;
    height: 300px;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 900px rgba(255,255,255,0.2);
    content: '';
    opacity: 0;
    -webkit-transform: scale3d(0.5,0.5,1);
    transform: scale3d(0.5,0.5,1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

figure.effect-lexi:hover img {
    opacity: 0.6;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-lexi h2 {
    text-align: left;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(5px,5px,0);
    transform: translate3d(5px,5px,0);
}

figure.effect-lexi p {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 1.5em 1.5em 0;
    width: 140px;
    text-align: right;
    opacity: 0;
    -webkit-transform: translate3d(20px,20px,0);
    transform: translate3d(20px,20px,0);
}

figure.effect-lexi:hover figcaption::before {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

figure.effect-lexi:hover h2,
figure.effect-lexi:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/*---------------*/
/***** Duke *****/
/*---------------*/

figure.effect-duke {
    background: -webkit-linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
    background: -webkit-linear-gradient(135deg, #34495e 0%,#cc6055 100%);
    background: linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
}

figure.effect-duke img,
figure.effect-duke p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-duke:hover img {
    opacity: 0.1;
    -webkit-transform: scale3d(2,2,1);
    transform: scale3d(2,2,1);
}

figure.effect-duke h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale3d(0.8,0.8,1);
    transform: scale3d(0.8,0.8,1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}

figure.effect-duke p {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 20px;
    padding: 30px;
    border: 2px solid #fff;
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    -webkit-transform: scale3d(0.8,0.8,1);
    transform: scale3d(0.8,0.8,1);
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
}

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
}

/* Media queries */
@media screen and (max-width: 50em) {
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}
/*
   Featured Work
*/
section.featured_work{}
.clear{clear:both;}
.featured_videos .grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 0em 0 0em;
    max-width: 1200px;
    list-style: none;
    text-align: center;
}

/* Common style */
.featured_videos .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 600px;
    min-height: 200px;
    max-height: 348px;
    width: 48%;
    height: auto;
    background: transparent;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
    margin:auto;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 94.5%;
}

/* Media queries */
@media screen and (max-width: 50em) {
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .featured_videos .grid figure {
        position: relative;
        float: none;
        overflow: hidden;
        margin: 10px 1%;
        width: 100%;
        height: auto;
        background: transparent;
        text-align: center;
        cursor: pointer;
    }
}
/*
   Block Quote
*/

section.block_quote{
    max-width:1400px;
    margin:auto;
}
blockquote{
    border-left:none
}
.hide{display:none !important;}

.quote-badge{
    background-color: rgba(0, 0, 0, 0.2);
}

.quote-box{
    overflow: hidden;
    padding: 10px;
    border-radius: 17px;
    /*background-color: #4ADFCC;*/
    margin:0px 2.66% 2.66%;
    color:white;
    cursor: pointer;
    width: 28%;display:inline-block;
    box-shadow: 2px 2px 2px 2px #E0E0E0;
    vertical-align: top;
}




.quote-box.green_1{
    background: #1f4037; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1f4037, #85dcb3); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #1f4037, #85DCB3);
    background: linear-gradient(to right, #1f4037, #85DCB3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.red_1{
    background: #e53935; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e53935, #e35d5b); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #e53935, #e35d5b);
    background: linear-gradient(to right, #e53935, #e35d5b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.blue_2{
    background: #485563; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #485563, #29323c); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #485563, #29323c);
    background: linear-gradient(to right, #485563, #29323c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.blue_1{
    background: #4b79a1; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4b79a1, #283e51); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #4b79a1, #283e51);
    background: linear-gradient(to right, #4b79a1, #283e51); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.quote-box.blue_3{
    background: #4776e6; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4776e6, #8e54e9); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #4776e6, #8e54e9);
    background: linear-gradient(to right, #4776e6, #8e54e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.quote-box.orange_1{
    background: #ff512f; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff512f, #f09819); /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #ff512f, #f09819);
    background: linear-gradient(to right, #ff512f, #f09819); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box:hover{
    background-color: #e2ac6b;
    background-image: -webkit-linear-gradient(left, #e2ac6b 0%, #cba36d 74%);
    background-image: linear-gradient(90deg, #e2ac6b 0%, #cba36d 74%);
}
/*
.quote-box.blue2{
    background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);
}
.quote-box.gold{
    background-color: #e2ac6b;
    background-image: linear-gradient(90deg, #e2ac6b 0%, #cba36d 74%);
}
.quote-box.blue:hover{
    background: #bdc3c7;
    background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50);
    background: linear-gradient(to right, #bdc3c7, #2c3e50);
}
.quote-box.darkblue{
    background: #373b44;
    background: -webkit-linear-gradient(to right, #373b44, #4286f4);
    background: linear-gradient(to right, #373b44, #4286f4);
}
.quote-box.red{
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}
.quote-box.green{
    background: #135058;
    background: -webkit-linear-gradient(to right, #16a085, #135058);
    background: linear-gradient(to right, #16a085, #135058);
}
.quote-box.google{
    background:linear-gradient(150deg, #4285f4,  #4285f4, #34a853, #34a853, #fbbc05, #f34335);
}
.quote-box.light_green{
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
}
.quote-box.light_green2{
    background: #16a085;
    background: -webkit-linear-gradient(to right, #16a085, #f4d03f);
    background: linear-gradient(to right, #16a085, #f4d03f); }
*/
.quote-box p{    padding:0px 4px 0px 4px; }
.quote-box p.blog-post-bottom{
    font-family: 'Montserrat', sans-serif;font-weight:600;
}

.quote-box:hover,
.quote-box:hover .quotation-mark{
    -webkit-transform:scale(1.03);
            transform:scale(1.03);
}
.quote-box:hover a{
    -webkit-transform:scale(1.05);
            transform:scale(1.05);
}
.quote-box a{
    display:block;
    padding:10px;border-radius:10px;float:right;
    color:inherit;text-decoration: none;
}
.quotation-mark{
    margin-bottom:20px;
    margin-top: -10px;
    font-weight: 600;
    font-size:90px;
    color:white;
    font-family: "Times New Roman", Georgia, Serif;
}
.quote-text{
    font-size: 17px;
    margin-top: -65px;
}
/*
   Header
*/
header{
    width:100%;height:76px;position:fixed;z-index:10;
    margin:auto;top:0px;left:0px;z-index:50;
    moz-transition: all .5s;-webkit-transition: all .5s;transition: all .5s;
    /*background-color: rgba(35, 35, 35, 0);*/
    background: -webkit-linear-gradient( top, #222, rgba(255, 255, 255, 0.0) );
    background: linear-gradient( to bottom, #222, rgba(255, 255, 255, 0.0) );
}
header.active{color:#fff;background-color:rgba(35, 35, 35, 0.97);}
header.stay_active{color:#fff;background-color:rgba(35, 35, 35, 0.97);}
body #nav-toggle {
    z-index: 101;
    position: fixed;
    top: 0;
    left: -20px;
    -webkit-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
}
body #nav-toggle-label:hover{-webkit-transform:scale(1.1);transform:scale(1.1);}
body #nav-toggle:checked+#nav-toggle-label,
body #nav-toggle:checked+#nav-toggle-label.slide-out{/*background:transparent;right:0*/
-webkit-transform:scale(1.5);
        transform:scale(1.5);}
body #nav-toggle:checked+#nav-toggle-label #hamburger span,
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span{width:0%}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(1),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(1){-webkit-transition-delay:0s;transition-delay:0s}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(2),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(2){-webkit-transition-delay:0.125s;transition-delay:0.125s}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(3),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(3){-webkit-transition-delay:0.25s;transition-delay:0.25s}
body #nav-toggle:checked+#nav-toggle-label #cross span:nth-child(1),
body #nav-toggle:checked+#nav-toggle-label.slide-out #cross span:nth-child(1){height:100%;-webkit-transition-delay:0.625s;transition-delay:0.625s}
body #nav-toggle:checked+#nav-toggle-label #cross span:nth-child(2),
body #nav-toggle:checked+#nav-toggle-label.slide-out #cross span:nth-child(2){width:100%;-webkit-transition-delay:0.375s;transition-delay:0.375s}
body #nav-toggle:checked+#nav-toggle-label ~ nav#main #nav-container a#logo.slide-out,
body #nav-toggle:checked+#nav-toggle-label.slide-out ~ nav#main #nav-container a#logo.slide-out{left:25px}

body #nav-toggle-label {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: transparent;
    position: fixed;
    z-index: 102;
    right: 3%;
    top: 11px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    cursor: pointer;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

body #nav-toggle-label #cross {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 38px;
    height: 38px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
body #nav-toggle-label #cross span {
    display: block;
    background: #fff;
}
body #nav-toggle-label #cross span:nth-child(1) {
    height: 0%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
body #nav-toggle-label #cross span:nth-child(2) {
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1px;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}
body #nav-toggle-label #hamburger {
    position: absolute;
    height: 28px;
    width: 40px;
    top: 14px;
    right: 1px;
}
body #nav-toggle-label #hamburger span {
    display: block;
    width: 80%;
    height: 2px;
    position: relative;
    margin: 0 0 8px 0;
    background: #fff;
}
body #nav-toggle-label #hamburger span:nth-child(1) {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}
body #nav-toggle-label #hamburger span:nth-child(2) {
    -webkit-transition-delay: 0.625s;
    transition-delay: 0.625s;
}
body #nav-toggle-label #hamburger span:nth-child(3) {
    -webkit-transition-delay: 0.75s;
    transition-delay: 0.75s;
}

@media (max-width: 1200px) {
    body #nav-toggle-label #hamburger {
        top: 14px;
        right: 1px;
        height: 28px;
    }
    body #nav-toggle-label #cross {
        top: 8px;
        right: 8px;
    }
    body #nav-toggle-label #hamburger span {
        margin: 0 0 8px 0 !important;
    }
}
/*
    Menu
*/
.hide{display:none;}
.tilted_menu{
    width: 0; height: 0;
    border-top: 0px solid transparent;
    border-bottom: 2000px solid transparent;
    border-right:0px solid #232323;
    /*width:500px;*/height:100vh;position:fixed;top:0px;right:0px;z-index:100;
    -webkit-transition:0.5s ease-in-out;transition:0.5s ease-in-out;

}
.tilted_menu .menu_content{margin:auto;padding-top:24vh;position:relative;right:-120px;}
.tilted_menu .menu_content ul{width:360px;text-align:right;list-style:none;}
.tilted_menu .menu_content ul li{/*font-family:Gotham_XLight;*/font-size:33px;margin-bottom:20px;text-align:right;}
.tilted_menu .menu_content ul li a{
    font-weight:300;letter-spacing:0.17px;
    color:#fff;text-align:right;text-decoration:none;font-family: 'Raleway', sans-serif;text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.tilted_menu .menu_content ul li a:hover,
.tilted_menu .menu_content ul li a.active{color:#cc9a60;}
.tilted_menu.active{border-right:500px solid #232323;}
/*
   Footer
*/
footer{width:100%;height:160px;background-color:#212121;}
footer .top{width:100%;height:91px;border-bottom:1px solid #3e3e3e;}
footer .logo:hover{-webkit-transform:scale(1.1);transform:scale(1.1);}
footer .logo{
    text-align:center;
    display:block;
    font-family: Gotham-Medium;
    letter-spacing: 4px;
    color:#fff;
    text-decoration: none;
    font-size:34px;
    text-transform: uppercase;
    padding-top:70px;
    position:relative;margin:0px auto 0px auto;
    background:transparent url('https://austincameron.com/res/images/logo_gray.png') no-repeat center center;background-size:80px auto;
}
footer .bottom{width:90%;margin:auto;height:70px;color:#EDEDED;font-size:18px;padding-top:25px;}
footer .copyright{float:left;color:#EDEDED;font-size:18px;font-family: 'Montserrat', sans-serif;}
footer .bottom .footer_nav{float:right;}
footer .bottom .footer_nav ul{float:right;list-style:none;margin:0px;}
footer .bottom .footer_nav ul li{float:left;margin-left:20px;}
footer .bottom .footer_nav ul li a{color:inherit;text-decoration:none;text-transform:uppercase;font-family: 'Montserrat', sans-serif;}
footer .bottom .footer_nav ul li a:hover{color:#cc9a60;text-decoration:underline;}




@media screen and (max-width: 600px) {
    footer .bottom .footer_nav{float:none;text-align: center;}
    footer .bottom .footer_nav ul{float:none;list-style:none;margin:auto;}
    footer .bottom .footer_nav ul li{float:left;margin:auto 20px auto;}
}
/*
   Tooltip
*/
body {
    font-family: Helvetica;
    font-size: 14px;
}

.container {
    margin-top: 120px;
    text-align: center;
    text-decoration: none;
}

p {
    margin-top: 30px;
    margin-bottom: 30px;
}

.tooltip {
    position: relative;
    display: inline;
}

.tooltip-trigger {
    display: inline-block;
    text-decoration: none;
}

.tooltip-bubble {
    min-width: 120px;
    max-width: 300px;
    position: absolute;
    z-index: 10;
}
.tooltip-bubble::after {
    content: '';
    position: absolute;
}

.tooltip-top {
    bottom: 100%;
    left: 50%;
    padding-bottom: 9px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.tooltip-top::after {
     border-left: 9px solid transparent;
     border-right: 9px solid transparent;
     border-top: 9px solid rgba(204, 154, 96, 0.9);
     bottom: 0;
     left: 50%;
     -webkit-transform: translateX(-50%);
             transform: translateX(-50%);
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    padding-top: 9px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.tooltip-bottom::after {
     border-left: 9px solid transparent;
     border-right: 9px solid transparent;
     border-bottom: 9px solid rgba(204, 154, 96, 0.9);
     top: 0;
     left: 50%;
     -webkit-transform: translateX(-50%);
             transform: translateX(-50%);
}

.tooltip-left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.tooltip-left::after {
     border-left: 9px solid rgba(204, 154, 96, 0.9);
     border-top: 9px solid transparent;
     border-bottom: 9px solid transparent;
     top: 50%;
     right: 0;
     -webkit-transform: translateY(-50%);
             transform: translateY(-50%);
}

.tooltip-right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.tooltip-right::after {
     border-right: 9px solid rgba(204, 154, 96, 0.9);
     border-top: 9px solid transparent;
     border-bottom: 9px solid transparent;
     top: 50%;
     left: 0;
     -webkit-transform: translateY(-50%);
             transform: translateY(-50%);
}

.tooltip-message {
    background: rgba(204, 154, 96, 0.9);
    border-radius: 3px;
    color: #fff;
    font-size:13px;line-height:17px;
    padding: 8px 1px 8px 1px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;font-weight:600;
}
