body #nav-toggle {
    z-index: 101;
    position: fixed;
    top: 0;
    left: -20px;
    -webkit-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
}
body #nav-toggle-label:hover{transform:scale(1.1);}
body #nav-toggle:checked+#nav-toggle-label,
body #nav-toggle:checked+#nav-toggle-label.slide-out{/*background:transparent;right:0*/
transform:scale(1.5);}
body #nav-toggle:checked+#nav-toggle-label #hamburger span,
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span{width:0%}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(1),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(1){-webkit-transition-delay:0s;-ms-transition-delay:0s;-moz-transition-delay:0s;-o-transition-delay:0s;transition-delay:0s}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(2),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(2){-webkit-transition-delay:0.125s;-ms-transition-delay:0.125s;-moz-transition-delay:0.125s;-o-transition-delay:0.125s;transition-delay:0.125s}
body #nav-toggle:checked+#nav-toggle-label #hamburger span:nth-child(3),
body #nav-toggle:checked+#nav-toggle-label.slide-out #hamburger span:nth-child(3){-webkit-transition-delay:0.25s;-ms-transition-delay:0.25s;-moz-transition-delay:0.25s;-o-transition-delay:0.25s;transition-delay:0.25s}
body #nav-toggle:checked+#nav-toggle-label #cross span:nth-child(1),
body #nav-toggle:checked+#nav-toggle-label.slide-out #cross span:nth-child(1){height:100%;-webkit-transition-delay:0.625s;-ms-transition-delay:0.625s;-moz-transition-delay:0.625s;-o-transition-delay:0.625s;transition-delay:0.625s}
body #nav-toggle:checked+#nav-toggle-label #cross span:nth-child(2),
body #nav-toggle:checked+#nav-toggle-label.slide-out #cross span:nth-child(2){width:100%;-webkit-transition-delay:0.375s;-ms-transition-delay:0.375s;-moz-transition-delay:0.375s;-o-transition-delay:0.375s;transition-delay:0.375s}
body #nav-toggle:checked+#nav-toggle-label ~ nav#main #nav-container a#logo.slide-out,
body #nav-toggle:checked+#nav-toggle-label.slide-out ~ nav#main #nav-container a#logo.slide-out{left:25px}

body #nav-toggle-label {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: transparent;
    position: fixed;
    z-index: 102;
    right: 3%;
    top: 11px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    cursor: pointer;
    -webkit-transition: 0.25s ease-in-out;
    -ms-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

body #nav-toggle-label #cross {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 38px;
    height: 38px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
body #nav-toggle-label #cross span {
    display: block;
    background: #fff;
}
body #nav-toggle-label #cross span:nth-child(1) {
    height: 0%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}
body #nav-toggle-label #cross span:nth-child(2) {
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1px;
    -webkit-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    transition-delay: 0.25s;
}
body #nav-toggle-label #hamburger {
    position: absolute;
    height: 28px;
    width: 40px;
    top: 14px;
    right: 1px;
}
body #nav-toggle-label #hamburger span {
    display: block;
    width: 80%;
    height: 2px;
    position: relative;
    margin: 0 0 8px 0;
    background: #fff;
}
body #nav-toggle-label #hamburger span:nth-child(1) {
    -webkit-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}
body #nav-toggle-label #hamburger span:nth-child(2) {
    -webkit-transition-delay: 0.625s;
    -ms-transition-delay: 0.625s;
    -moz-transition-delay: 0.625s;
    -o-transition-delay: 0.625s;
    transition-delay: 0.625s;
}
body #nav-toggle-label #hamburger span:nth-child(3) {
    -webkit-transition-delay: 0.75s;
    -ms-transition-delay: 0.75s;
    -moz-transition-delay: 0.75s;
    -o-transition-delay: 0.75s;
    transition-delay: 0.75s;
}

@media (max-width: 1200px) {
    body #nav-toggle-label #hamburger {
        top: 14px;
        right: 1px;
        height: 28px;
    }
    body #nav-toggle-label #cross {
        top: 8px;
        right: 8px;
    }
    body #nav-toggle-label #hamburger span {
        margin: 0 0 8px 0 !important;
    }
}