/*
   Footer
*/
footer{width:100%;height:160px;background-color:#212121;}
footer .top{width:100%;height:91px;border-bottom:1px solid #3e3e3e;}
footer .logo:hover{transform:scale(1.1);}
footer .logo{
    text-align:center;
    display:block;
    font-family: Gotham-Medium;
    letter-spacing: 4px;
    color:#fff;
    text-decoration: none;
    font-size:34px;
    text-transform: uppercase;
    padding-top:70px;
    position:relative;margin:0px auto 0px auto;
    background:transparent url('https://austincameron.com/res/images/logo_gray.png') no-repeat center center;background-size:80px auto;
}
footer .bottom{width:90%;margin:auto;height:70px;color:#EDEDED;font-size:18px;padding-top:25px;}
footer .copyright{float:left;color:#EDEDED;font-size:18px;font-family: 'Montserrat', sans-serif;}
footer .bottom .footer_nav{float:right;}
footer .bottom .footer_nav ul{float:right;list-style:none;margin:0px;}
footer .bottom .footer_nav ul li{float:left;margin-left:20px;}
footer .bottom .footer_nav ul li a{color:inherit;text-decoration:none;text-transform:uppercase;font-family: 'Montserrat', sans-serif;}
footer .bottom .footer_nav ul li a:hover{color:#cc9a60;text-decoration:underline;}




@media screen and (max-width: 600px) {
    footer .bottom .footer_nav{float:none;text-align: center;}
    footer .bottom .footer_nav ul{float:none;list-style:none;margin:auto;}
    footer .bottom .footer_nav ul li{float:left;margin:auto 20px auto;}
}