/*
   Tooltip
*/
body {
    font-family: Helvetica;
    font-size: 14px;
}

.container {
    margin-top: 120px;
    text-align: center;
    text-decoration: none;
}

p {
    margin-top: 30px;
    margin-bottom: 30px;
}

.tooltip {
    position: relative;
    display: inline;
}

.tooltip-trigger {
    display: inline-block;
    text-decoration: none;
}

.tooltip-bubble {
    min-width: 120px;
    max-width: 300px;
    position: absolute;
    z-index: 10;
}
.tooltip-bubble::after {
    content: '';
    position: absolute;
}

.tooltip-top {
    bottom: 100%;
    left: 50%;
    padding-bottom: 9px;
    transform: translateX(-50%);
}
.tooltip-top::after {
     border-left: 9px solid transparent;
     border-right: 9px solid transparent;
     border-top: 9px solid rgba(204, 154, 96, 0.9);
     bottom: 0;
     left: 50%;
     transform: translateX(-50%);
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    padding-top: 9px;
    transform: translateX(-50%);
}
.tooltip-bottom::after {
     border-left: 9px solid transparent;
     border-right: 9px solid transparent;
     border-bottom: 9px solid rgba(204, 154, 96, 0.9);
     top: 0;
     left: 50%;
     transform: translateX(-50%);
}

.tooltip-left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    transform: translateY(-50%);
}
.tooltip-left::after {
     border-left: 9px solid rgba(204, 154, 96, 0.9);
     border-top: 9px solid transparent;
     border-bottom: 9px solid transparent;
     top: 50%;
     right: 0;
     transform: translateY(-50%);
}

.tooltip-right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    transform: translateY(-50%);
}
.tooltip-right::after {
     border-right: 9px solid rgba(204, 154, 96, 0.9);
     border-top: 9px solid transparent;
     border-bottom: 9px solid transparent;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
}

.tooltip-message {
    background: rgba(204, 154, 96, 0.9);
    border-radius: 3px;
    color: #fff;
    font-size:13px;line-height:17px;
    padding: 8px 1px 8px 1px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;font-weight:600;
}