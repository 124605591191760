*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-text-size-adjust: none;
}
body{font-family: 'Raleway', sans-serif;}
.logo{
  z-index: 10000;
  letter-spacing: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 34px;
  padding-left: 25px;
  text-transform: uppercase;
  position: fixed;
  top: 10px;left:20px;
  width: 80px;
  height: 55px;
  background: transparent url(https://austincameron.com/res/images/logo_gray.png) no-repeat center center;
  background-size: 70px auto;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.video-react{position:relative;z-index:-1;  }
.hero_copy{
  overflow:hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  position:absolute;z-index:20;
  width:100%;height:100%;
  background: linear-gradient(
          to top,
          #222222,
          rgba(255, 255, 255, 0)
  );
}
.hero_copy svg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 79px;
}

@media (max-width: 699px) {
  .svg--lg {
    display: none;
  }
}

@media (min-width: 700px) {
  .svg--sm {
    display: none;
  }
}
.hero_copy h2{
  width:100%;
  font-size:68px;
  position:absolute;bottom:65px;
  color:#fff;text-align:center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', sans-serif;font-weight:300;
}
.hero_copy h3{
  width:100%;letter-spacing:0.9px;
  font-size:32px;font-style:italic;
  position:absolute;bottom:55px;
  color:#e9f5ff;text-align:center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', sans-serif;font-weight:200;
}


.about_text_header{}
.about_text_header h4{letter-spacing:3px;color:#cc9a60;text-align:center;font-size:20px;font-family: 'Montserrat-Bold', sans-serif;text-transform:uppercase;margin:40px auto 30px;}
.about_text_header h2{letter-spacing:2px;width:73%;color:#010101;text-align:center;line-height:32px;font-size:24px;font-family: 'Montserrat', sans-serif;margin:0px auto 10px;}
.about_text_header h3{width:68%;color:#4f4f4f;text-align:center;line-height:26px;font-size:17px;font-family: 'Raleway', sans-serif;font-weight:400;margin:0px auto 0px;}
.about_text_header .cta.wide{width:210px;}
.about_text_header .cta{
    box-shadow: 0 2px 10px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.23);
    border-radius:8px;padding:0px;margin:20px auto 30px;background-color:#cc9a60;color:#fff;text-decoration:none;display:block;text-align:center;width:148px;height:44px;text-transform:uppercase;line-height:45px;font-size:15px;font-family: 'Raleway', sans-serif;font-weight:600;}

.homepage_video{width:100%;min-height:236px;padding-bottom: 60px;height:auto;background-color:#050505;}
.homepage_video h2{letter-spacing:2px;text-transform:uppercase;text-align:center;width:90%;padding-top:45px;margin:0px auto 40px;color:#fff;font-family: 'Montserrat', sans-serif;font-size:26px;line-height:36px;}
.homepage_video iframe,
.ReactYoutube,
.homepage_video embed{margin:0px auto 0px;display:block;max-width:711px;}
.about_text_header .cta:hover,
.homepage_video iframe:hover{
  transform:scale(1.04);
}
.ReactYoutube,
iframe,embed{width:100% !important;height:400px;}
.ReactYoutube-PlayAwait{width:100% !important;height:100% !important;}

.text_header{
  width:100%;height:auto;
}
.text_header h5{
  font-family: 'Playball', cursive;letter-spacing:2px;font-weight:400;
  margin-top: 40px;
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: #4d4d4d;
  margin-bottom: 40px;
}
.text_header h5:after {
  content: '';
  display: block;
  margin: auto;
  width: 10%;
  height: 10px;
  border-bottom: 1px solid #cc9a60;
}




/*
   Fade In & Up
*/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp
}

/*
   Page Transitions
*/
.animate-page-in {
  -webkit-animation: fadeInPage .4s ease-in;
  animation: fadeInPage .4s ease-in;
  background-color:#222;
}
.animate-page-out {
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
  opacity: 0;
  background-color:#222;
}
@-webkit-keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}






















  @media (max-width: 768px) { /* Responsive */
      header{
         /* background: -webkit-linear-gradient( top, #666, rgba(255, 255, 255, 0.0) );
          background: linear-gradient( to bottom, #666, rgba(255, 255, 255, 0.0) );*/
          height:50px !important;
      }
      .logo {
          top: -5px;
          left: -10px;
          background-size: 40px auto;
      }
      body #nav-toggle-label {
          right: 2% !important;
          top: -5px !important;
      }
      body #nav-toggle:checked+#nav-toggle-label,
      body #nav-toggle:checked+#nav-toggle-label.slide-out {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
      }

      .about_text_header h2 {
          letter-spacing: 1.25px;
          width: 90%;
          line-height: 24px;
          font-size: 15px;
      }
      .about_text_header h3 {
          width: 94%;
          line-height: 24px;
          font-size: 11px;
          margin: 0px auto 30px;
      }
      .ReactYoutube,
      iframe,embed{width:90% !important;height:250px;}
      .quote-box{width:90% !important;margin:0px auto 15px !important;display:block !important;}
      .grid{max-width:90%;}
      .grid figure {min-width:300px;}
      .text_header h5{font-size:32px;}
      .contact_form.fadeInUp .sub_container {
          width: 100% !important;
      }
      .quotation-mark {
          margin-bottom: 22px;
          font-size: 84px;
      }
      .quote-text {
          font-size: 16px;
      }
      .quote-box p.blog-post-bottom{margin-top:10px;margin-bottom:-5px;}
      .homepage_video h2 {
          letter-spacing: 1.5px;
          width: 100%;
          padding-top: 30px;
          margin: 0px auto 25px;
          font-size: 11px;
          line-height: 22px;
      }
      footer .bottom{text-align: center;padding-top: 9px !important;}
      footer .bottom .footer_nav,
      footer .copyright{float:none !important;margin:auto;display:block;text-align: center;}
      footer .bottom .footer_nav ul li a {font-size: 13px;}
      footer .bottom .footer_nav ul { float: none;}

  }

@media (max-width: 500px) { /* Responsive */

    .hero_copy svg{height:30px;}
    .hero_copy h2{bottom:36px;font-size:32px;}
    .hero_copy h3{bottom:26px;font-size:16px;}
}






