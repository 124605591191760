/*
   Header
*/
header{
    width:100%;height:76px;position:fixed;z-index:10;
    margin:auto;top:0px;left:0px;z-index:50;
    moz-transition: all .5s;-webkit-transition: all .5s;transition: all .5s;
    /*background-color: rgba(35, 35, 35, 0);*/
    background: -webkit-linear-gradient( top, #222, rgba(255, 255, 255, 0.0) );
    background: linear-gradient( to bottom, #222, rgba(255, 255, 255, 0.0) );
}
header.active{color:#fff;background-color:rgba(35, 35, 35, 0.97);}
header.stay_active{color:#fff;background-color:rgba(35, 35, 35, 0.97);}