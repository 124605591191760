/*
   Block Quote
*/

section.block_quote{
    max-width:1400px;
    margin:auto;
}
blockquote{
    border-left:none
}
.hide{display:none !important;}

.quote-badge{
    background-color: rgba(0, 0, 0, 0.2);
}

.quote-box{
    overflow: hidden;
    padding: 10px;
    border-radius: 17px;
    /*background-color: #4ADFCC;*/
    margin:0px 2.66% 2.66%;
    color:white;
    cursor: pointer;
    width: 28%;display:inline-block;
    box-shadow: 2px 2px 2px 2px #E0E0E0;
    vertical-align: top;
}




.quote-box.green_1{
    background: #1f4037; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1f4037, #85dcb3); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1f4037, #85DCB3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.red_1{
    background: #e53935; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e53935, #e35d5b); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e53935, #e35d5b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.blue_2{
    background: #485563; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #485563, #29323c); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #485563, #29323c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box.blue_1{
    background: #4b79a1; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4b79a1, #283e51); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4b79a1, #283e51); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.quote-box.blue_3{
    background: #4776e6; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4776e6, #8e54e9); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4776e6, #8e54e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.quote-box.orange_1{
    background: #ff512f; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff512f, #f09819); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff512f, #f09819); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.quote-box:hover{
    background-color: #e2ac6b;
    background-image: linear-gradient(90deg, #e2ac6b 0%, #cba36d 74%);
}
/*
.quote-box.blue2{
    background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);
}
.quote-box.gold{
    background-color: #e2ac6b;
    background-image: linear-gradient(90deg, #e2ac6b 0%, #cba36d 74%);
}
.quote-box.blue:hover{
    background: #bdc3c7;
    background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50);
    background: linear-gradient(to right, #bdc3c7, #2c3e50);
}
.quote-box.darkblue{
    background: #373b44;
    background: -webkit-linear-gradient(to right, #373b44, #4286f4);
    background: linear-gradient(to right, #373b44, #4286f4);
}
.quote-box.red{
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}
.quote-box.green{
    background: #135058;
    background: -webkit-linear-gradient(to right, #16a085, #135058);
    background: linear-gradient(to right, #16a085, #135058);
}
.quote-box.google{
    background:linear-gradient(150deg, #4285f4,  #4285f4, #34a853, #34a853, #fbbc05, #f34335);
}
.quote-box.light_green{
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
}
.quote-box.light_green2{
    background: #16a085;
    background: -webkit-linear-gradient(to right, #16a085, #f4d03f);
    background: linear-gradient(to right, #16a085, #f4d03f); }
*/
.quote-box p{    padding:0px 4px 0px 4px; }
.quote-box p.blog-post-bottom{
    font-family: 'Montserrat', sans-serif;font-weight:600;
}

.quote-box:hover,
.quote-box:hover .quotation-mark{
    transform:scale(1.03);
}
.quote-box:hover a{
    transform:scale(1.05);
}
.quote-box a{
    display:block;
    padding:10px;border-radius:10px;float:right;
    color:inherit;text-decoration: none;
}
.quotation-mark{
    margin-bottom:20px;
    margin-top: -10px;
    font-weight: 600;
    font-size:90px;
    color:white;
    font-family: "Times New Roman", Georgia, Serif;
}
.quote-text{
    font-size: 17px;
    margin-top: -65px;
}